import { json } from '@remix-run/node'
import { Outlet, useLoaderData } from '@remix-run/react'
import classnames from 'classnames'
import { Fragment } from 'react'
import { MAX_WIDTH } from '~/components/landing-pages/constants'
import Footer from '~/components/landing-pages/footer'
import TopNav from '~/components/navigation/top-nav'
import { SCHEDULE_BUILDER_FE_URL } from '~/constants/env-variables.server'
import { getUserProfile } from '~/services/auth.server'
import type { LoaderFunctionArgs } from '@remix-run/node'

export async function loader({ request }: LoaderFunctionArgs) {
  const profile = await getUserProfile(request, { allowUnauthenticated: true })

  return json({
    profile: profile?.profile || null,
    scheduleBuilderUrl: SCHEDULE_BUILDER_FE_URL
  })
}

export default function WebsiteLayout() {
  const { profile, scheduleBuilderUrl } = useLoaderData<typeof loader>()

  return (
    <Fragment>
      <div className={classnames('mx-auto flex w-full flex-col gap-y-10 text-brand-darkest', 'md:gap-y-14', 'lg:gap-y-20', MAX_WIDTH)}>
        <TopNav profile={profile} scheduleBuilderUrl={scheduleBuilderUrl} />
        <Outlet />
      </div>

      <Footer />
    </Fragment>
  )
}
